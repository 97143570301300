import getCookie from 'browser/utils/getCookie';

export const saveVistorId = () => {
  const visitorId = getCookie('m_vis_id');

  localStorage.setItem('m_vis_id', visitorId);
};

export const getVisitorId = () =>
  getCookie('m_vis_id') || localStorage.getItem('m_vis_id') || '';

export const eventsNames = {
  // click events
  click_wybierz_mieszkanie: 'click_wybierz_mieszkanie',
  click_ulubione_wyszukiwarka_dodaj: 'click_ulubione_wyszukiwarka_dodaj',
  click_ulubione_wyszukiwarka_usun: 'click_ulubione_wyszukiwarka_usun',
  click_ulubione_karta_dodaj: 'click_ulubione_karta_dodaj',
  click_ulubione_karta_usun: 'click_ulubione_karta_usun',
  click_pobierz_pdf: 'click_pobierz_pdf',
  click_prospekt_informacyjny: 'click_prospekt_informacyjny',
  click_mieszkanie_karta: 'click_mieszkanie_karta',
  click_powiekszenie: 'click_powiekszenie',
  click_pietro_zmiana_wyszukiwarka: 'click_pietro_zmiana_wyszukiwarka',
  click_pietro_karta_zmiana: 'click_pietro_karta_zmiana',
  click_pietro_wyszukiwarka: 'click_pietro_wyszukiwarka',
  click_numer_kondygnacji_wyszukiwarka: 'click_numer_kondygnacji_wyszukiwarka',
  click_mieszkanie_wyszukiwarka: 'click_mieszkanie_wyszukiwarka',
  click_spacer_3d_wyszukiwarka: 'click_spacer_3d_wyszukiwarka',
  click_spacer_3d_karta: 'click_spacer_3d_karta',
  click_social_media: 'click_social_media',
  click_telefon: 'click_telefon',

  // CONTACT FORM TOP
  formularz_gora_wypelnianie_start: 'formularz_gora_wypelnianie_start',
  formularz_gora_wyslany: 'formularz_gora_wyslany',
  formularz_gora_blad_wysylki: 'formularz_gora_blad_wysylki',
  // CONTACT FORM BOTTOM
  formularz_dol_wypelnianie_start: 'formularz_dol_wypelnianie_start',
  formularz_dol_wyslany: 'formularz_dol_wyslany',
  formularz_dol_blad_wysylki: 'formularz_dol_blad_wysylki',
  // FLAT FORM
  formularz_mieszkanie_wypelnianie_start:
    'formularz_mieszkanie_wypelnianie_start',
  formularz_mieszkanie_wyslany: 'formularz_mieszkanie_wyslany',
  formularz_mieszkanie_blad_wysylki: 'formularz_mieszkanie_blad_wysylki',
  formularz_mieszkanie_wyswietlenie: 'formularz_mieszkanie_wyswietlenie',
  // RESERVATION FORM
  formularz_rezerwacja_wypelnianie_start:
    'formularz_rezerwacja_wypelnianie_start',
  formularz_rezerwacja_wyslany: 'formularz_rezerwacja_wyslany',
  formularz_rezerwacja_blad_wysylki: 'formularz_rezerwacja_blad_wysylki',
  formularz_rezerwacja_wyswietlenie: 'formularz_rezerwacja_wyswietlenie',
};

const emitMatomoEvent = event => {
  const mtmEvent = {
    ...event,
    visitorId: getVisitorId(),
  };

  if (typeof window !== `undefined`) {
    if (window._mtm) {
      window._mtm.push(mtmEvent);
    }
  }
};

export default emitMatomoEvent;
