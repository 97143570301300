import React, { createContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import scrollToSection from 'utils/scrollToSection';

export const NavigationContext = createContext();

const NavigationProvider = ({ children }) => {
  const [initialSearchParam, setInitialSearchParam] = useState('');

  useEffect(() => {
    const isBrokenGoogleLink = window.location.hash.includes('?mtm_source');

    if (isBrokenGoogleLink) {
      const indexOfQuestionMark = window.location.hash.indexOf('?');
      const anchorSubstring = window.location.hash.substring(
        0,
        indexOfQuestionMark
      );
      const mtmSubstring = window.location.hash
        .substring(indexOfQuestionMark)
        .replace('?', '&');

      const fixedInitialSearchParam = `${window.location.search}${mtmSubstring}`;
      setInitialSearchParam(fixedInitialSearchParam);

      setTimeout(() => {
        scrollToSection(anchorSubstring, 'instant');
      }, 1);
      return;
    }

    setInitialSearchParam(window.location.search);

    const indexOfHashMark = window.location.hash.indexOf('#');
    if (indexOfHashMark === -1) return;
    const anchorSubstring = window.location.hash.substring(indexOfHashMark);
    setTimeout(() => {
      scrollToSection(anchorSubstring, 'instant');
    }, 1);
  }, []);

  return (
    <NavigationContext.Provider
      value={{
        initialSearchParam,
      }}
    >
      {children}
    </NavigationContext.Provider>
  );
};

NavigationProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default NavigationProvider;
